<template>
  <v-app style="background-color:#FFC702">
    <section>
      <v-container grid-list-xl>
        <v-layout row wrap justify-center class="my-5">
          <v-flex>
            <v-card class="elevation-10">
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">Location</div>
              </v-card-title>
              <v-card-text>
                <iframe
                  width="100%"
                  height="450"
                  frameborder="0"
                  style="border:1"
                  src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ51j8tQxQP4cRFZH2_tMcIeo&key=AIzaSyArpTzkGrOqXN3I_nSCbfFXiDDMRm1CIxk"
                  allowfullscreen
                ></iframe>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm5>
            <v-card class="elevation-10">
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">Contact us</div>
              </v-card-title>
              <v-card-text class="layout justify-center">
                Call for Orders of 10 or More.
              </v-card-text>
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon class="blue--text text--lighten-2">phone</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      ><a href="tel:+9703163484"
                        >(970) 316-3484</a
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon class="blue--text text--lighten-2">hours</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      >Mon-Fri 6:30am to 12:00pm</v-list-item-title
                    >
                    <v-list-item-title
                      >Sat 7:30 to 12:00 Noon</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>
  </v-app>
</template>

<script>
export default {
  name: "location",

  data() {
    return {
      center: { lat: 38.45159, lng: -107.86523 },
      markers: [{ position: { lat: 38.45159, lng: -107.86523 } }]
    };
  }
};
</script>
