<template>
  <v-app light>
    <v-main style="background-color:#FFC702">
      <v-layout column wrap class="my-5" align-center>
        <v-flex>
          <section>
            <v-card class="mx-auto" max-width="750" outlined>
              <v-img src="/img/green_chili.jpg" height="550px"></v-img>
              <v-card-title class="display-1">Chile Explained</v-card-title>

              <v-card-text class="headline justify-center">
                Green Chile is made from Hatch Green Chiles in either mild, hot
                or 1/2 & 1/2 (mix of mild and hot). Red Chile is made mild and
                is in the chicken burrito Christmas is mixing the red and green
                together.<br /><br />
                If hot is not hot enough for you Levi has created an extra hot
                salsa that can be in the burrito or on the side XX.
              </v-card-text>
            </v-card>
          </section>
        </v-flex>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "explained"
  // data() {
  //   return {
  //     loading: false,
  //     menu: []
  //   };
  // },
  // mounted() {
  //   firestore()
  //     .collection("menu")
  //     .orderBy("name")
  //     .get()
  //     .then(dbresp => {
  //       this.loading = true;
  //       dbresp.forEach(i => this.menu.push(i.data()));
  //       this.loading = false;
  //       console.log(this.menu.map(v => v.ingredients));
  //     });
  // },
};
</script>
