
import firebase from "firebase";
import Vue from "vue";

interface MItem {
  name: string;
  icon: string;
  price: number;
  ingredients: Ingredient[];
}

interface Ingredient {
  name: string;
  color: string;
}

export default Vue.extend({
  name: "menu-items",
  data() {
    return {
      loading: false,
      menu: new Array<MItem>()
    };
  },
  mounted() {
    firebase
      .firestore()
      .collection("menu")
      .orderBy("name")
      .get()
      .then(dbresp => {
        //this.loading = true;
        this.menu = dbresp.docs.map(i => {
          let x = i.data();
          return {
            name: x.name ? x.name : "",
            icon: x.icon ? x.icon : "help",
            price: x.price ? x.price : 0,
            ingredients: x.ingredients
          };
        });
        //this.loading = false;
      });
  }
  // props: { items: { type: Array, required: false } }
});
