
import firebase from "firebase";
import Vue from "vue";

interface DItem {
  name: string;
  icon: string;
  type: string;
}

interface DrinkPrice {
  size: string;
  price: number;
}

interface Extras {
  flavors: string[];
}

export default Vue.extend({
  name: "drink-items",
  data() {
    return {
      loading: false,
      hot_drinks: new Array<DItem>(),
      cold_drinks: new Array<DItem>(),
      prices: new Array<DrinkPrice>(),
      extras: {
        flavors: new Array<string>()
      }
    };
  },
  mounted() {
    firebase
      .firestore()
      .collection("drinks")
      .get()
      .then(dbresp => {
        this.loading = true;
        dbresp.docs.forEach(i => {
          let d = i.data();
          if (d.name) {
            if (d.values) {
              this.prices = d.values.map((p: any) => {
                return {
                  size: p.name,
                  price: p.value
                };
              });
            } else if (d.flavors) {
              this.extras = d.flavors;
            } else if (d.type) {
              if (d.type === "hot") {
                this.hot_drinks.push({
                  name: d.name,
                  icon: d.icon,
                  type: d.type
                });
              }
              if (d.type === "cold") {
                this.cold_drinks.push({
                  name: d.name,
                  icon: d.icon,
                  type: d.type
                });
              }
            }
          }
          if (d.flavors) {
            this.extras.flavors = d.flavors;
          }
        });
        this.cold_drinks.sort((a, b) =>
          a.name > b.name ? 1 : a.name < b.name ? -1 : 0
        );
        this.loading = false;
      });
  }
});
