import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import firebase from "firebase";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.config.productionTip = false;

const config = {
  apiKey: "AIzaSyDkrX1j17KU_OcBE5fMbvYgsOpyOo8gKvI",
  authDomain: "sunrise-burritos.firebaseapp.com",
  databaseURL: "https://sunrise-burritos.firebaseio.com",
  projectId: "sunrise-burritos",
  storageBucket: "sunrise-burritos.appspot.com",
  messagingSenderId: "250614414969"
};
firebase.initializeApp(config);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyArpTzkGrOqXN3I_nSCbfFXiDDMRm1CIxk",
    libraries: "places" // necessary for places input
  }
});

Vue.filter("currency", (v: number) => {
  if (!v) {
    return "NA";
  }
  return `$${v.toFixed(2)}`;
});

Vue.filter("phone", (phone: number) => {
  return phone
    .toString()
    .replace(/[^0-9]/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
