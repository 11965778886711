<template>
  <v-app light>
    <div class="about">
      <h1>This is an about page</h1>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "about"
  // data() {
  //   return {
  //     loading: false,
  //     menu: []
  //   };
  // },
  // mounted() {
  //   firestore()
  //     .collection("menu")
  //     .orderBy("name")
  //     .get()
  //     .then(dbresp => {
  //       this.loading = true;
  //       dbresp.forEach(i => this.menu.push(i.data()));
  //       this.loading = false;
  //       console.log(this.menu.map(v => v.ingredients));
  //     });
  // },
};
</script>
