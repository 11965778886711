<template>
  <v-app dark>
    <v-main style="background-color:#FFC702">
      <section>
        <v-layout column wrap class="my-5" align-center>
          <v-flex xs12 sm4 class="my-3">
            <div class="text-xs-center">
              <h2 class="headline">Our Menu</h2>
              <!-- <span class="subheading">
                Made to order... But don't be a dick about it
              </span> -->
            </div>
          </v-flex>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap align-center>
                <menu-items />
                <drink-items />
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>
    </v-main>
  </v-app>
</template>

<script>
// @ is an alias to /src
import MenuItems from "@/components/MenuItems.vue";
import DrinkItems from "@/components/DrinkItems.vue";

export default {
  name: "sunrisemenu",
  data() {
    return {
      loading: false,
      menu: []
    };
  },
  // mounted() {
  //   firestore()
  //     .collection("menu")
  //     .orderBy("name")
  //     .get()
  //     .then(dbresp => {
  //       this.loading = true;
  //       dbresp.forEach(i => this.menu.push(i.data()));
  //       this.loading = false;
  //       console.log(this.menu.map(v => v.ingredients));
  //     });
  // },
  components: {
    MenuItems,
    DrinkItems
  }
};
</script>
