
import firebase from "firebase";
import Vue from "vue";

interface CItem {
  name: string;
  icon: string;
  url: string;
}

export default Vue.extend({
  name: "community-items",
  data() {
    return {
      loading: false,
      community: new Array<CItem>()
    };
  },
  mounted() {
    firebase
      .firestore()
      .collection("community")
      .get()
      .then(dbresp => {
        this.loading = true;
        dbresp.docs.forEach(i => {
          let d = i.data();
          if (d.name) {
            this.community.push({
              name: d.name,
              icon: d.image,
              url: d.url
            });
          }
        });
        this.loading = false;
      });
  }
});
