<template>
  <v-app style="background-color:#FFC702">
    <section>
      <v-layout column wrap class="my-5" align-center>
        <v-flex xs12 sm4 class="my-3">
          <div class="text-xs-center">
            <h2 class="headline">
              We are proud to have been raised in Montrose Colorado and would
              like to give back to the community that has helped us.
            </h2>
            <span class="subheading">
              Here are some of the organization that we support:
            </span>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-center>
              <community-items />
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section>
  </v-app>
</template>

<script>
import CommunityItems from "@/components/CommunityItems.vue";

export default {
  name: "community",
  data() {
    return {
      // loading: false,
      // : []
    };
  },
  components: {
    CommunityItems
  }
};
</script>
