import Vue from "vue";
import VueRouter from "vue-router";
import SunriseMenu from "../views/SunriseMenu.vue";
import About from "../views/About.vue";
import ChileExplained from "../views/ChileExplained.vue";
import Community from "../views/Community.vue";
import GiftCards from "../views/GiftCards.vue";
import HoursLocation from "../views/HoursLocation.vue";
import FoodTruck from "../views/FoodTruck.vue";
import Merchandise from "../views/Merchandise.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/"
  },
  {
    path: "/",
    component: SunriseMenu,
    alias: "/menu"
  },
  {
    path: "/menu",
    name: "menu",
    component: SunriseMenu
  },
  {
    path: "/about",
    name: "about",
    component: About
  },
  {
    path: "/explained",
    name: "explained",
    component: ChileExplained
  },
  {
    path: "/community",
    name: "community",
    component: Community
  },
  {
    path: "/gift_cards",
    name: "gift_cards",
    component: GiftCards
  },
  {
    path: "/location",
    name: "location",
    component: HoursLocation
  },
  {
    path: "/truck",
    name: "truck",
    component: FoodTruck
  },
  {
    path: "/merchandise",
    name: "merchandise",
    component: Merchandise
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

export default router;
