import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      egg: "mdi-egg",
      cow: "mdi-cow",
      pig: "mdi-pig",
      sasauge: "mdi-sasauge",
      chicken: "/img/chicken-icon.png"
    }
  }
});
