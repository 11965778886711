import { render, staticRenderFns } from "./DrinkItems.vue?vue&type=template&id=336784a4"
import script from "./DrinkItems.vue?vue&type=script&lang=ts"
export * from "./DrinkItems.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports