
import Vue from "vue";
import firebase from "firebase";

export default Vue.extend({
  name: "App",
  data() {
    return {
      notice: { render: false, value: null },
      alert: { render: false, value: null },
      truck: { running: false }
    };
  },
  mounted() {
    firebase
      .firestore()
      .collection("alerts")
      .get()
      .then(dbresp => {
        dbresp.docs.map(i => {
          let v = i.data();
          if (v.alert && v.alert.trim() !== "") {
            this.alert.render = true;
            this.alert.value = v.alert;
          }
          if (v.notice && v.notice.trim() !== "") {
            this.notice.render = true;
            this.notice.value = v.notice;
          }
        });
      });

    firebase
      .firestore()
      .collection("truck")
      .doc("details")
      .get()
      .then(resp => {
        let info = resp.data();
        if (resp.exists && info) {
          this.truck.running = info.running;
        }
      });
  }
});
